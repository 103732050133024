import zxcvbn from 'zxcvbn';

const minLengthRegex = /^.{8,}$/;
const uppercaseOrNumberRegex = /(?=.*[A-Z])|(?=.*[0-9])/;

export default {
  minLength: minLengthRegex,
  uppercaseOrNumber: uppercaseOrNumberRegex,
  isStrong: (password = '') => minLengthRegex.test(password) && uppercaseOrNumberRegex.test(password) && zxcvbn(password)?.score > 1,
};
