export default () => ({
  required: {
    required: true,
    message: useT('abc576'),
    trigger: 'submit',
  },
  email: {
    type: 'email',
    message: useT('abc577'),
    trigger: 'submit',
  },
  url: {
    type: 'url',
    message: useT('abc215'),
    trigger: 'submit',
  },
  password: [
    { required: true, message: useT('abc578'), trigger: 'submit' },
    { strength: 'minLength', regexp: true, pattern: passwordStrength.minLength, message: useT('abc579'), trigger: 'submit' },
    { strength: 'uppercaseOrNumber', regexp: true, pattern: passwordStrength.uppercaseOrNumber, message: useT('abc580'), trigger: 'submit' },
    {
      validator: (rule, value) => passwordStrength.isStrong(value),
      message: useT('abc1152'),
      trigger: 'submit',
    },
  ],
  passwordConfirm: (confirmValue) => {
    return [{
      validator: (rule, value, callback) => {
        if (value === '') {
          callback(new Error(useT('abc581')));
        } else if (value !== confirmValue.value) {
          callback(new Error(useT('abc582')));
        } else {
          callback();
        }
      },
      trigger: 'blur',
    }];
  },
  startDate: (endDate) => {
    return {
      validator: (rule, value) => {
        return value ? useDayjs(value).isBefore(endDate.value) : true;
      },
      message: useT('abc583'),
      trigger: 'submit',
    };
  },
  endDate: (startDate) => {
    return {
      validator: (rule, value) => {
        return value ? useDayjs(value).isAfter(startDate.value) : true;
      },
      message: useT('abc583'),
    };
  },
});
